import { ComponentRef } from "@angular/core";
import { KeyMap } from "src/app/models/shared";
import { AnyTarget } from "src/app/pages//channels/channel";
import { ZxTargetTargetsColumnComponent } from "./zx-target-targets-column.component";

export function assignComponentsTargetTargetsAdapter(
    targetComponentRef: ComponentRef<ZxTargetTargetsColumnComponent>,
    row: KeyMap<AnyTarget>,
    searchTerm: string[]
) {
    const targetComponentInstance = targetComponentRef.instance;
    targetComponentInstance.target = row;
    targetComponentInstance.searchTermArray = searchTerm;
}
